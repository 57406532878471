<template>
  <el-dialog :title="options.title" :visible.sync="visible" :close-on-click-modal="false" width="600px">
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="confirmForm">确 定</el-button>
    </span>

    <el-form ref="form" :model="form" label-width="100px">

      <el-form-item label="资料名称" :rules="[{ required: true, message: '请输入资料名称'}]">
        <el-input v-model="form.name"></el-input>
      </el-form-item>

      <el-form-item label="上传文件" :rules="[{ required: true, message: '请上传文件'}]">
        <upload-single v-model="form.url" @change="uploadChange" />
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import _ from 'lodash';

export default {
  data() {
    return {
      visible: false,
      options: {},
      form: {},
      defaultForm: {  },
    }
  },

  methods: {
    async show(initForm, options = {}) {
      this.form = _.assign({}, this.defaultForm, initForm, {});
      this.options = options;
      this.visible = true;
    },

    async confirmForm() {
      try {
        await this.$api.execute('sys_file.create', _.assign({
        }, _.pick(this.form, ['path', 'url', 'file_type', 'size', 'name'])));
        this.options.success && this.options.success();
        this.visible = false;
      } catch(e) {
        this.$notify({
          type: 'error',
          message: e.message
        });
      }
    },

    uploadChange(e) {
      this.form = _.assign({}, this.form, {}, _.pick(e, ['name', 'file_type', 'size']))
      console.log('uploadChange', e)
    }
    
  }
}
</script>